@tailwind base;

html {
  scroll-behavior: smooth;
}

body {
  text-transform: lowercase;
}

h1, h2 {
  font-size: 3.625rem;
  line-height: 1.125;
  font-weight: bold;
  margin-bottom: .75em;
}

@screen xl {
  h1, h2 {
    font-size: 5.625rem;
  }
}

h3 {
  font-size: theme("fontSize.xl");
  line-height: 1.35;
  font-weight: bold;
  margin-bottom: .75em;
}


p {
  font-size: theme("fontSize.xl");
  line-height: 1.875;
}

@tailwind components;

.btn {
  display: inline-block;
  font-weight: theme("fontWeight.bold");
  font-size: theme("fontSize.2xl");
  padding: theme("padding.6") theme("padding.12");
  color: theme("colors.white");
  background-color: theme("colors.neutral.900");
  border-radius: theme("borderRadius.default");
  border: theme("borderWidth.2") solid theme("colors.transparent");
  text-align: center;
  transition: transform .2s ease-in-out;
  transform: translateY(0px)
}

.btn:active {
  transform: translateY(2px)
}

.btn:disabled {
  opacity: .75;
}

@screen xl {
  .btn {
    font-size: theme("fontSize.3xl");
    padding: theme("padding.6") theme("padding.16");
  }
}

.btn--white {
  color: theme("colors.black");
  background-color: theme("colors.white");
}

.btn--hollow-white {
  color: theme("colors.white");
  background-color: theme("colors.transparent");
  border: theme("borderWidth.2") solid theme("colors.white");
}

.btn--primary {
  color: theme("colors.white");
  background-color: theme("colors.primary.500");
}

.btn--hollow-primary {
  color: theme("colors.primary.500");
  background-color: theme("colors.white");
  border: theme("borderWidth.2") solid theme("colors.primary.500");
}

/* ========================================================================
   Form
 ========================================================================== */


/* ========================================================================
   Label / Headings / Text
 ========================================================================== */

label,
legend {
  display: inline-block;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

button,
select {
  text-transform: none;
}

/* ========================================================================
   Input
 ========================================================================== */

.form-control {
  width: 100%;
  padding: 2rem 1.5rem;

  background: theme("colors.neutral.300");

  border: 1;
  border-radius: theme("borderRadius.default");
  border-color: theme("colors.neutral.400");
  outline: 0;

  appearance: none;

  transition: 0.2s ease-in-out;
  transition-property: background-color;
}

.form-control::placeholder {
  color: theme("colors.neutral.600");
}

.form-control:focus {
  background: theme("colors.neutral.200");
}

textarea {
  overflow: auto;
  resize: vertical;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: .75rem;
  padding: 0;
  background: #d3d3d3;
  outline: none;
  opacity:0.7;
  -webkit-transition: opacity .15s ease-in-out;
  transition: opacity .15s ease-in-out;
}
input[type="range"]:hover {
  opacity:1;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: theme("colors.primary.500");
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border: 0;
  border-radius: 50%;
  background: theme("colors.primary.500");
  cursor: pointer;
}

/* ========================================================================
   Select
 ========================================================================== */

select:not([multiple]):not([size]) {
  max-width: 100%;
  padding-right: 2.5rem;
  font-weight: 500;
  background-image: url("data:image/svg+xml,%3Csvg style='fill:%23b9b9b9'%0Axmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' width='284.929px' height='284.929px' viewBox='0 0 284.929 284.929' xml:space='preserve'%3E%3Cpath d='M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441 L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082 c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647 c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  background-size: 1em;
}

select:not([multiple]):not([size]):focus {
  background-image: url("data:image/svg+xml,%3Csvg style='fill:%23868686'%0Axmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' width='284.929px' height='284.929px' viewBox='0 0 284.929 284.929' xml:space='preserve'%3E%3Cpath d='M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441 L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082 c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647 c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z'/%3E%3C/svg%3E%0A");
}

select[multiple] {
  overflow: auto;
  padding: 0;
}

select[multiple] option {
  padding: 0.5rem 0.75rem;
  margin: 0;
}

/* ========================================================================
   Radio / Checkbox
 ========================================================================== */

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
  transform: scale(1.5);
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-input:disabled ~ .form-check-label {
  color: theme("colors.neutral.800");
}

.empty-hidden:empty {
  display: none;
}


@tailwind utilities;

.css-spin {
  animation: spin 3000ms linear  infinite;
  transform-box: fill-box; 
  transform-origin: center;
}

.css-spin-reverse {
  animation: spin 3000ms linear  infinite;
  animation-direction: reverse;
  transform-box: fill-box; 
  transform-origin: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-origin {
  transform-box: fill-box; 
  transform-origin: center;
}

.eye-blink {
  animation: blink 5000ms linear infinite;
  transform-box: fill-box; 
  transform-origin: center;
}

.eye-blink-delayed {
  animation: blink 5000ms linear infinite 2500ms;
  transform-box: fill-box; 
  transform-origin: center;
}

@keyframes blink {
  0% {
    transform: scale(1);
  }
  94% {
    transform: scale(1);

  }
  95% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}